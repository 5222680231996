import React from "react"
import ErrorHandler from "./src/components/errorHandler";
import Layout from "./src/components/layout";
import SEO from "./src/components/seo"
import GdprReminder from "./src/components/gdprReminder";
import Modal from "./src/components/modal";

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element , props}) => {
    return (
        <ErrorHandler><SEO/>
        <Modal />
        <GdprReminder location={props.location} />
        <Layout {...props}>{element}</Layout>
        </ErrorHandler>
    )
}
