import { observer, inject } from "mobx-react"
import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import ReactDOM from 'react-dom';

import { VisitorStore } from "stores";
import { getLanguageValue } from 'utils';

import Presentation from './presentation';
import { SquidexPrivacySettings } from 'generated/gatsbyApiTypes';

@inject("visitorStore")
@observer
export default class GdprReminder extends React.Component<any, any> {
  private el: HTMLElement;
  constructor(props: any) {
    super(props);
    this.state = {
      open: !props.visitorStore.current.hasConsentedToGDPR,
      ppError: false
    }
    if (typeof (document) !== "undefined") {
      this.el = document.getElementById("gbp_gdpr_modal");
    }
  }
  render() {
    if (typeof (window) === "undefined" || !this.state.open) {
      return null;
    }
    return ReactDOM.createPortal(
      <StaticQuery query={graphql`{
        settings: squidexPrivacySettings(identity: { eq: "privacySettings"}) {
          txtPrivacyModalHeader {
            de
            nl
            en
          }
          txtPrivacyModalIntro {
            de
            nl
            en
          }
          privacyPolicyHref {
            iv
          }
          txtPrivacyPolicy {
            de
            nl
            en
          }
          txtAnalyticsInformation {
            de
            nl
            en
          }
          btnConfirm {
            de
            nl
            en
          }
        }
      }`} render={(data: {settings: SquidexPrivacySettings}) => {
          let open;
          var pathParts = decodeURI(this.props.location.pathname).toLowerCase().split("/").filter(p => p !== "");
          var ppParts = data.settings.privacyPolicyHref.iv.toLowerCase().split("/").filter(p => p !== "");
          for (let i = 0; i < ppParts.length; i++) {
            if (ppParts[i] !== pathParts[i]) {
              open = true;
            }
          }
          open = open || ppParts.length !== pathParts.length;
          open = this.state.open && open;
          return <Presentation
            btnConfirmText={getLanguageValue(data.settings.btnConfirm)}
            privacyPolicyError={this.state.ppError}
            open={open}
            privacyPolicyHref={data.settings.privacyPolicyHref.iv.toLowerCase()}
            txtAnalyticsInformation={getLanguageValue(data.settings.txtAnalyticsInformation)}
            txtPrivacyModalHeader={getLanguageValue(data.settings.txtPrivacyModalHeader)}
            txtPrivacyModalIntro={getLanguageValue(data.settings.txtPrivacyModalIntro)}
            txtPrivacyPolicy={getLanguageValue(data.settings.txtPrivacyPolicy)}
            onConfirm={this.handleConfirm}
            onAnalyticsConsentChange={this.handleAnalyticsConsent}
            onPrivacyPolicyChange={this.handlePrivacyPolicyConsent}
          />
        }} />, this.el);
  }
  handleConfirm = () => {
    const store: VisitorStore = this.props.visitorStore;
    if (store.current.hasConsentedToGDPR) {
      this.setState({ open: false });
      store.startAnalytics();
    } else {
      this.setState({ ppError: true });
    }
  }
  handlePrivacyPolicyConsent = (value: boolean) => {
    const store: VisitorStore = this.props.visitorStore;
    store.current.hasConsentedToGDPR = value;
  }
  handleAnalyticsConsent = (value: boolean) => {
    const store: VisitorStore = this.props.visitorStore;
    store.current.hasConsentedToAnalytics = value;
  }
}


