
import * as React from "react";

import { SquidexGallery } from 'generated/gatsbyApiTypes';

import GalleryPageCollectionItem from './galleryPageCollectionItem';

export interface GalleryPageCollectionContainerProps {
  nodes: SquidexGallery[]
  onClick?: (page: SquidexGallery) => void;
}

export default class GalleryPageCollectionContainer extends React.Component<GalleryPageCollectionContainerProps> {
  render() {
    return (
      <div className="flex flex-row flex-wrap mr0-2nc-nl mr0-4nc-l">
        {
          this.props.nodes.map((node, i) =>
            <GalleryPageCollectionItem
              node={node}
              onClick={this.onClick}
              key={`gallerypagecollectionitem-${i}`}
            />
          )
        }
      </div>
    )
  }
  onClick = (node: SquidexGallery) => {
    if (this.props.onClick) {
      this.props.onClick(node);
    }
  }
}


