
import * as React from "react";
import { Debounced } from "../../utils";

export interface InputProps {
  horizontal?: boolean;
  className?: string;
  label?: string;
  initialValue?: string | number;
  onChange?: (value: string) => void;
}

export default class Input extends React.Component<InputProps> {
  debouncer: Debounced;
  constructor(props: InputProps) {
    super(props);
    this.debouncer = new Debounced();
  }
  render() {
    const label = this.props.label ? <label className="f6 b db mb2 tl">{this.props.label}</label> : undefined;
    const style = "input-reset w-100 ba b--black-20 pa2 db outline-0";
    const cs = this.props.className ? this.props.className : "";
    let defaultValue = this.props.initialValue;
    if (isNumber(defaultValue)) {
      defaultValue = defaultValue.toString();
    }
    if (!this.props.horizontal) {
      return (
        <>
          {label}
          <input className={`${style} ${cs}`} type="text" onChange={this.handleChange} defaultValue={defaultValue} />
        </>
      )
    }
    return (
      <div className="db flex-ns items-center">
        <div className="fl w-third-ns w-100">
          {label}
        </div>
        <div className="fl w-two-thirds-ns w-100">
          <input className={`${style} ${cs}`} type="text" defaultValue={defaultValue} onChange={this.handleChange} />
        </div>
      </div>
    )
  }
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.debouncer.set(this.notify(e.target.value), 400);
  }
  notify = (value: string) => () => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }
}
function isNumber(value?: string | number): value is number
{
   return !isNaN(Number(value));
}


