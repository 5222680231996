import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import * as React from "react";

export interface IconButtonProps {
    className?: string;
    color?: string;
    textColor?: string;
    inversed?: boolean;
    icon: IconProp;
    onClick?: (t: React.MouseEvent<HTMLButtonElement>) => void;
}

export default class IconButton extends React.Component<IconButtonProps> {
    render() {
        const color = this.props.color ? this.props.color : "gray";
        const bg = this.props.inversed ? "bg-white" : "bg-" + color;
        // const bColor = this.props.inversed ? "b--" + color : "";
        // const ba = this.props.inversed ? "ba" : "bn";  
        const className = this.props.className ? this.props.className + " " : ""
        const cs = `${className} tc dim pointer dib pa2 ${color} bg-white bn`;
        return (<button className={cs} onClick={this.props.onClick} ><FontAwesomeIcon icon={this.props.icon} /></button>)
    }
}


