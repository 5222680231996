

import * as React from "react";

export interface BreadcrumbsProps {
  location: {
    pathname: string;
  }
}

export default class Breadcrumbs extends React.Component<BreadcrumbsProps> {

  render() {
    const path: string = this.props.location.pathname;
    if (path === "/" || path === "/index.html") {
      return null;
    }
    const pathParts = path.split("/").reduce((acc, p, i) => {
      if (i !== 0 && p === "") { return acc; }
      const label = i === 0 ? "Home" : capitalize(decodeURIComponent(p));
      const href = i === 0 ? "/" : acc[acc.length - 1].href + p + "/";
      acc.push({ label, href });
      return acc;
    }, []).concat();

    return (
      <div className="pa2">
        {pathParts.map((part, i) => this.renderBreadcrumb(part, i, pathParts.length))}
      </div>
    )
  }
  renderBreadcrumb = (props: { label: string, href: string }, i: number, length: number) => {
    return <React.Fragment key={`crumb-${i}`}>
      {
        i == length
          ? <span className="b">{props.label}</span>
          : <a className="link dim" href={props.href}>{props.label}</a>
      }
      {i == length - 1 ? undefined : <span className="pl1 pr1">/</span>}
    </React.Fragment>
  }
}

const capitalize = (t: string) => {
  return t[0].toUpperCase() + t.substr(1);
}

