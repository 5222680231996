import * as React from "react";
import Img from "gatsby-image";
import { IPromiseBasedObservable } from "mobx-utils";
import { observer } from 'mobx-react';

import { Material, Format, ImageGallery } from 'stores';
import { Button } from 'components';
import { SquidexGalleryItem } from 'generated/gatsbyApiTypes';
import { getFluidImageSharp } from 'utils';

export interface GalleryItemProps {
  node: SquidexGalleryItem;
  orderButtonText: string;
  clickToEnlargeText: string;
  onMaterialText: string;
  forPriceText: string;
  material?: Material;
  format?: IPromiseBasedObservable<Format>;
  gallery?: ImageGallery;
  single?: boolean;
  onImageClick?: (image: any) => void;
  onOrderClick?: (node: any, material?: Material, format?: IPromiseBasedObservable<Format>) => void;
}

@observer
export default class GalleryItem extends React.Component<GalleryItemProps> {
  render() {
    const node = this.props.node;
    const img = node ? getFluidImageSharp(node.image) : undefined;
    if (!img) {
      return null;
    }
    const title = this.props.node && this.props.node.name ? this.props.node.name.iv : "";
    const description = this.props.node && this.props.node.description ? this.props.node.description.iv : ""
    let priceInfo;
    if (this.props.material) {
      let imagePrice = this.props.gallery ? this.props.gallery.price : 0;
      if (this.props.format.state === "fulfilled") {
        priceInfo = (
          <p className="f7 lh-copy mb2">
            {this.props.format.value.height}x{this.props.format.value.width}
            {this.props.onMaterialText}
            {this.props.material.name} {this.props.forPriceText} &euro; {(this.props.format.value.price + imagePrice).toFixed(2)}
          </p>
        );
      }
    }
    let cs = "inline-flex flex-column tc mb2 shadow-3 h-100 w-100"
    // if (!this.props.single) {
    //   cs += " w-49 w-32-l"
    // } else {
    //   cs += " w-100"
    // }
    return (
      <div id={this.props.single ? undefined : node.id} className={cs}>
        <a onClick={this.handleImageClick} className="dim pointer hide-child relative">
          <Img fluid={img} alt={title} />
          <div className={`absolute child w-100 h-50 left-0 pa2`} style={{top: "40%"}}>
              <h3 className="ma0 w-100 black tc">{this.props.clickToEnlargeText}</h3>
          </div>
        </a>
          <h5 className="ma0 lh-copy f6 mt2">{title}</h5>
          <p className={`f7 i lh-copy mb2 ${priceInfo ? "" : "flex-grow-1"}`}>{description}</p>
          {priceInfo}
          <Button className="self-center mb2 mr1 ml1" color="theme" label={this.props.orderButtonText} onClick={this.handleOrderClick} />
      </div>
    )
  }
  handleImageClick = () => {
    this.props.onImageClick(this.props.node);
  }
  handleOrderClick = () => {
    this.props.onOrderClick(this.props.node, this.props.material, this.props.format);
  }
}


