import axios, { AxiosResponse } from "axios";

import { IApiClient, IMaybeResult, ICreateOrderResponse, ICustomSizePriceResponse } from "./interfaces";
import { Shop, Order, Giftcard, Coupon } from "stores";

export * from "./interfaces";

export class ApiClient implements IApiClient {
  apiUrl: string;
  host: string;
  shopId: number;
  constructor(host: string, apiUrl: string, shopId: number | string) {
    this.host = host;
    this.apiUrl = apiUrl;

    if (typeof (shopId) === "string") {
      this.shopId = parseInt(shopId);
    } else {
      this.shopId = shopId;
    }
  }
  getShop(): Promise<IMaybeResult<Shop>> {
    return axios({
      method: "POST",
      url: `${this.apiUrl}/shop/info`,
      data: {
        ShopId: this.shopId
      }
    }).then(
      (response: AxiosResponse) => ({
        result: Shop.from(response.data)
      }),
      handleError<Shop>()
    );
  }
  getCoupon(code: string): Promise<IMaybeResult<Coupon>> {
    return axios({
      method: "POST",
      url: `${this.apiUrl}/coupon/info`,
      data: {
        CouponCode: code
      }
    }).then(
      (response: AxiosResponse) => ({
        result: new Coupon(response.data.code, response.data.price, response.data.type)
      }),
      handleError<Coupon>()
    )
  }
  getGiftcard(code: string): Promise<IMaybeResult<Giftcard>> {
    return axios({
      method: "POST",
      url: `${this.apiUrl}/giftcard/info`,
      data: {
        GiftcardCode: code
      }
    }).then(
      (response: AxiosResponse) => ({
        result: new Giftcard(response.data.code, response.data.amount)
      }),
      handleError<Giftcard>()
    )
  }
  getCustomSizePrice(materialId: number, height: number, width: number): Promise<IMaybeResult<ICustomSizePriceResponse>> {
    return axios({
      method: "POST",
      url: `${this.apiUrl}/material/customSize`,
      data: {
        MaterialId: materialId,
        Height: height,
        Width: width
      }
    }).then(
      (response: AxiosResponse) => ({
        result: response.data
      }),
      handleError<ICustomSizePriceResponse>()
    )
  }
  placeOrder(order: Order, returnUrl: string, cancelUrl: string): Promise<IMaybeResult<ICreateOrderResponse>> {
    var orderRequest = {
      ReturnUrl: this.host + returnUrl,
      CancelUrl: this.host + cancelUrl,
      LastOrderId: 0,
      ShopId: this.shopId,
      Customer: order.customer,
      PaymentMethod: order.paymentMethod,
      Remarks: order.remarks,
      Giftcard: order.giftCard.code,
      CouponCode: order.coupon.code,
      Upsells: order.upsells.map(u => u.id),
      Items: order.items.map((item, i) => ({
        MaterialId: item.material.id,
        FormatId: item.format ? item.format.id : undefined,
        GalleryId: item.imageGallery ? item.imageGallery.id : undefined,
        Options: item.options.map(o => o.id),
        ImageName: "image" + i,
        Image: item.srcImage,
        Amount: item.amount,
        Crop: item.crop.toRequest(),
        Width: item.width,
        Height: item.height,
        IsCustomSize: item.isCustomSize,
        Effect: item.effect,
        Latrichting: item.latrichting
      }))
    };
    return axios({
      method: "POST",
      url: `${this.apiUrl}/order`,
      data: orderRequest
    }).then(
      (response: AxiosResponse) => ({
        result: response.data
      }),
      handleError<ICreateOrderResponse>()
    )
  }

}
export function handleError<T>(): (data: any) => IMaybeResult<T> {
  return (data) => ({
    result: undefined
  });
}
