
import * as React from "react";

import { SquidexProductPageQualitiesDe } from "../../generated/gatsbyApiTypes"

import ProductQualitiesContainer from './ProductQualitiesContainer';

export interface ProductQualitiesProps {
  nodes: SquidexProductPageQualitiesDe[]
  className?: string;
}

export default class ProductQualities extends React.Component<ProductQualitiesProps> {
  render() {
    return (<ProductQualitiesContainer className={this.props.className} nodes={this.props.nodes} />)
  }
}


