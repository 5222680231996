
import * as React from "react";
import ProductPageCollectionItem from './productPageCollectionItem';
import { SquidexProductPage } from 'generated/gatsbyApiTypes';

export interface ProductPageCollectionContainerProps {
  nodes: SquidexProductPage[]
  btnText: string
  onClick?: (page: SquidexProductPage) => void;
}

export default class ProductPageCollectionContainer extends React.Component<ProductPageCollectionContainerProps> {
  render() {
    return (
      <div className="flex flex-row flex-wrap mr0-2nc-nl mr0-3nc-l">
        {
          this.props.nodes.map((node, i) =>
            <ProductPageCollectionItem
              node={node}
              btnText={this.props.btnText}
              onClick={this.onClick}
              key={`productpagecollectionitem-${i}`}
            />
          )
        }
      </div>
    )
  }
  onClick = (node: SquidexProductPage) => {
    if (this.props.onClick) {
      this.props.onClick(node);
    }
  }
}


