
import * as React from "react";

import { SquidexProductPage } from 'generated/gatsbyApiTypes';

import ProductPageCollectionContainer from './productPageCollectionContainer';

export interface ProductPageCollectionProps {
  nodes: SquidexProductPage[];
  btnText: string;
  navigate: (route: string) => void;
}

export default class ProductPageCollection extends React.Component<ProductPageCollectionProps> {
  render() {
    return (<ProductPageCollectionContainer nodes={this.props.nodes} onClick={this.onClick} btnText={this.props.btnText}/>)
  } 
  onClick = (node: SquidexProductPage) => {
    this.props.navigate(node.slug.iv.toLowerCase())
  }
}


