
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"

import { SquidexConfig, SquidexTexts, SquidexMenuConnection } from 'generated/gatsbyApiTypes';

import MobileMenuPresentation from './mobileMenuPresentation';
import DesktopMenuPresentation from './desktopMenuPresentation';
import "./navbar.less";
import { getLanguageValue } from 'utils';

interface QueryData {
  squidexConfig: SquidexConfig;
  squidexTexts: SquidexTexts;
  allSquidexMenu: SquidexMenuConnection
}

export default class NavbarComponent extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      openedMenu: undefined,
      menuOpen: false
    }
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
    {
      squidexConfig(identity: {eq: "config"}) {
        title {
          iv
        }
      }
      squidexTexts(identity: {eq: "squidextexts"}) {
        txtHeaderTagline {
          de
          nl
          en
        }
      }
      allSquidexMenu {
        edges {
          node {
            menuItems {
              name {
                iv
              }
              href {
                iv
              }
              submenu {
                name {
                  iv
                }
                href {
                  iv
                }
              }
            }
          }
        }
      }
    }    
        `}
        render={(data: QueryData) => {
          const menu = data.allSquidexMenu.edges[0].node;
          const title = data.squidexConfig.title.iv;
          const tagline = getLanguageValue(data.squidexTexts.txtHeaderTagline);
          return (
            <section id="nav">
              <DesktopMenuPresentation nodes={menu.menuItems} navigate={this.props.navigate} title={title} tagline={tagline} />
              <MobileMenuPresentation nodes={menu.menuItems} title={title} tagline={tagline} />
            </section>
          )
        }} />
    )
  }
}


