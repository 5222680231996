import * as React from "react";

import "./loaders.css"

export interface LoaderProps {
    inline?: boolean;
    color?: string;
}

export default class Loader extends React.Component<LoaderProps> {
    render() {
        const childStyle = this.props.color ? { backgroundColor: this.props.color } : undefined;
        return (
            <div className={this.props.inline ? undefined : "loading"}>
                <div className="ball-pulse w5">
                    <div style={childStyle}></div>
                    <div style={childStyle}></div>
                    <div style={childStyle}></div>
                </div>
            </div>
        );
    }
}


