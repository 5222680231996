
import * as React from "react";

import { SquidexGallery } from 'generated/gatsbyApiTypes';

import GalleryPageCollectionContainer from './galleryPageCollectionContainer';

export interface GalleryPageCollectionProps {
  nodes: SquidexGallery[]
  navigate: (route: string) => void;
}

export default class GalleryPageCollection extends React.Component<GalleryPageCollectionProps> {
  render() {
    return (<GalleryPageCollectionContainer nodes={this.props.nodes} onClick={this.onClick} />)
  } 
  onClick = (node: SquidexGallery) => {
    this.props.navigate(node.slug.iv.toLowerCase())
  }
}


