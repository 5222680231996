
import * as React from "react";

export interface FancyNumberProps {
    className?: string;
    color?: string;
    textColor?: string;
    size?: number;
    number: number;
}

export default class FancyNumber extends React.Component<FancyNumberProps> {
    render() {
        const color = this.props.color ? this.props.color : "theme";
        const textColor = this.props.textColor ? this.props.textColor : "near-white";
        const bg = "bg-" + color;
        const className = this.props.className ? this.props.className + " " : ""
        const size = this.props.size ? "f" + this.props.size : "f4";
        const cs = `tc ${size} lh-copy ${textColor} ${bg} br-100 ${className} `;
        return (<div className={cs} style={{width: "1.5em"}}>{this.props.number}</div>)
    }
}


