export const PAGE_VIEW_EVENT = "view_page"
export const PAGE_NOT_FOUND_EVENT = "page_not_found"
export const NAVIGATE_EVENT = "navigate" 
export const SELECT_IMAGE_EVENT = "select_image"
export const UPLOAD_IMAGE_EVENT ="upload_image"
export const SELECT_MATERIAL_EVENT = "select_material"
export const SELECT_FORMAT_EVENT = "select_format"
export const SELECT_EFFECT_EVENT = "select_effect"
export const SELECT_FORMAT_FILTER_EVENT = "select_format_filter"
export const INPUT_CUSTOM_FORMAT_EVENT = "input_custom_format"
export const SELECT_OPTION_EVENT = "select_option"
export const CHANGE_IMAGE_EVENT = "change_image"
export const ADD_IMAGE_TO_CART_EVENT = "add_image_to_cart"
export const ADD_ANOTHER_IMAGE_EVENT = "add_another_image_event"
export const EDIT_IMAGE_FROM_CART_EVENT = "edit_image_from_cart"
export const REMOVE_IMAGE_FROM_CART_EVENT = "remove_image_from_cart"
export const SELECT_UPSELL_EVENT = "select_upsell"
export const INPUT_COUPON_EVENT = "input_coupon"
export const INPUT_GIFTCARD_EVENT = "input_giftcard"
export const INPUT_DETAILS_EVENT = "input_details"
export const PLACE_ORDER_EVENT = "place_order"
export const CAUGHT_EXCEPTION_EVENT = "caught_exception"
export const THUMBNAIL_MAX = 400;
export const NO_OP = () => {};
