
import * as React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { IPromiseBasedObservable } from "mobx-utils";

import { Material, Format, ImageGallery } from 'stores';
import { SquidexGalleryItem } from 'generated/gatsbyApiTypes';


import GalleryItem from './galleryItem';

export interface GalleryItemsContainerProps {
  nodes: SquidexGalleryItem[]
  orderButtonText: string;
  onMaterialText: string;
  clickToEnlargeText: string;
  forPriceText: string;
  loading: boolean;
  gallery?: ImageGallery;
  single?: boolean;
  getMaterial: (item: SquidexGalleryItem) => Material;
  getFormat: (item: SquidexGalleryItem, material?: Material) => IPromiseBasedObservable<Format>;
  onImageClick?: (image: any, i: number) => void;
  onOrderClick?: (node: any, material?: Material, format?: IPromiseBasedObservable<Format>) => void;
}


export default class GalleryItemsContainer extends React.Component<GalleryItemsContainerProps> {

  render() {
    return (
      <ResponsiveMasonry columnsCountBreakPoints={!this.props.single ? {480: 2, 800: 3, 1000: 4} : {0:1}}>
        <Masonry gutter="2em">
          {
            this.props.nodes.map((node, i) => {
              let material;
              let format;
              // if (!this.props.loading) {
              //   material = this.props.getMaterial(node);
              //   format = this.props.getFormat(node, material);
              // } 
              return (
                <GalleryItem
                  node={node}
                  forPriceText={this.props.forPriceText}
                  format={format}
                  material={material}
                  gallery={this.props.gallery}
                  onImageClick={this.onImageClick(i)}
                  onMaterialText={this.props.onMaterialText}
                  onOrderClick={this.onOrderClick}
                  orderButtonText={this.props.orderButtonText}
                  clickToEnlargeText={this.props.clickToEnlargeText}
                  single={this.props.single}
                  key={`galleryitem-${i}`}
                />
              )
            })
          }
        </Masonry>
      </ResponsiveMasonry>
      // <div className="flex flex-row flex-wrap justify-between">

      // </div>
    )
  }
  onImageClick = (i: number) => (image: any) => {
    if (this.props.onImageClick) {
      this.props.onImageClick(image, i);
    }
  }
  onOrderClick = (node: any, material?: Material, format?: IPromiseBasedObservable<Format>) => {
    if (this.props.onOrderClick) {
      this.props.onOrderClick(node, material, format);
    }
  }
}


