import * as React from 'react';

import { GatsbyPageProps } from './gatsby-types';
import { VisitorStore, OrderStore, ShopStore, UIStore } from 'stores';

export interface TraumwandPageProps<C = any,D = any> extends GatsbyPageProps<C, D> {
  visitorStore?: VisitorStore;
  orderStore?: OrderStore;
  shopStore?: ShopStore;
  uiStore?: UIStore;
}

export class TraumwandPageComponent<D = any, C = any, S = any> extends React.Component<TraumwandPageProps<D, C>, S>
{

}
