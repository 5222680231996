import { observable, action, computed } from "mobx";

export class Visitor 
{
  constructor() {
    this.seenMaterialIds = [];
    this.hasConsentedToAnalytics = true;
    this.hasConsentedToGDPR = false;
  }
  @observable lastMaterialId: number;
  @observable seenMaterialIds: number[];
  @observable hasConsentedToGDPR: boolean;
  @observable hasConsentedToAnalytics: boolean;
  @computed public get hasSeenMaterials() {
    return this.seenMaterialIds.length > 0;
  }
  @action spot(id: number) {
    this.lastMaterialId = id;
    this.seenMaterialIds.push(id);
  }
}
