import * as React from "react";

export interface SeperatorProps {
    color?: string;
}

export default class Seperator extends React.Component<SeperatorProps> {
    render() {
        const color = this.props.color ? this.props.color : 'black-20';
        return (
            <div className={`b--${color} bb mt2 mb2`} />
        );
    }
}


