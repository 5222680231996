import { observable, computed } from "mobx";
import { Upsell, Material, Format, Option, ImageGallery } from "../shopStore";
import { THUMBNAIL_MAX } from '../../utils/constants';

export class Order {
  @observable paymentMethod: string;
  @observable remarks: string;
  @observable giftCard: Giftcard;
  @observable coupon: Coupon;
  @observable upsells: Upsell[];
  @observable items: OrderItem[];
  @observable customer: Customer;
  @observable id: string;

  constructor() {
    this.items = [];
    this.upsells = [];
    this.items = [];
    this.customer = new Customer();
    this.coupon = new Coupon();
    this.giftCard = new Giftcard();
    this.paymentMethod = "";
    this.remarks = "";
    this.id = "";
  }

  @computed get itemSubTotal() {
    return this.items.reduce((acc, next) => acc + next.price * next.amount, 0);
  }
  @computed get subTotal() {
    return this.upsells.reduce((sum, upsell) => sum + this.getUpsellPrice(upsell), this.itemSubTotal);
  }
  @computed get discount() {
    if (this.coupon.isValid) {
      switch (this.coupon.type) {
        case "PERCENT": return this.itemSubTotal * (this.coupon.price / 100);
        case "VALUE": return this.coupon.price;
      }
    }
    return 0;
  }
  @computed get cardAmount() {
    if (this.giftCard.isValid) {
      return this.giftCard.amount;
    }
    return 0;
  }
  getUpsellPrice = (upsell: Upsell) => {
    if (!upsell) {
      return 0;
    }
    switch (upsell.priceType) {
      case "PERCENT": return this.itemSubTotal * (upsell.price / 100);
      case "VALUE": return upsell.price;
    }
  }
}

export class OrderItem {
  @observable material: Material;
  @observable format?: Format;
  @observable imageGallery?: ImageGallery;
  @observable options: Option[];
  crop: Crop;
  image?: string;
  srcImage?: string;
  @observable thumb?: string;
  @observable amount: number;
  @observable width: number;
  @observable height: number;
  @observable effect: string;
  @observable filter: string;
  @observable latrichting: string;
  @observable rawPrice: number;
  @computed get price() {
    return this.options.reduce((sum, o) => sum + this.getOptionPrice(o), this.rawPrice + this.galleryPrice);
  }
  @computed get galleryPrice() {
    if (this.imageGallery) {
      if (this.imageGallery.type === "Percentage") {
        return this.rawPrice * this.imageGallery.price / 100;
      } else {
        return this.imageGallery.price;
      }
    }
    return 0;
  }
  @computed get isCustomSize() {
    return this.material && this.material.customSizes;
  }
  @computed get absWidth() {
    return this.isCustomSize
      ? this.width ? this.width : 1
      : this.format ? this.format.width : 1;
  }
  @computed get absHeight() {
    return this.isCustomSize
      ? this.height ? this.height : 1
      : this.format ? this.format.height : 1;
  }
  @computed get aspectRatio() {
    return this.absWidth / this.absHeight;
  }
  @computed get hasThumb() {
    return this.thumb && this.thumb !== "";
  }
  @computed get hasImage() {
    return this.image && this.image !== "";
  }
  constructor() {
    this.crop = new Crop();
    this.options = [];
    this.amount = 1;
    this.latrichting = "";
  }
  getOptionPrice = (option: Option) => {
    if (!option) {
      return 0;
    }
    switch (option.priceType) {
      case "VALUE": return option.price;
      case "PERCENT": return option.price / 100 * this.rawPrice;
    }
    return option.price;
  }
}

export class Crop {
  @observable angle: number;
  @observable x: number;
  @observable y: number;
  @observable width: number;
  @observable height: number;
  @observable originalWidth: number;
  @observable originalHeight: number;

  public toRequest(): Crop {
    var newCrop = new Crop();
    newCrop.angle = this.angle ? this.angle : 0;
    const scale = this.originalHeight > this.originalWidth 
      ? this.originalHeight / THUMBNAIL_MAX
      : this.originalWidth / THUMBNAIL_MAX;
    newCrop.x = Math.round(this.x * scale);
    newCrop.y = Math.round(this.y * scale);
    newCrop.width = Math.min(Math.round(this.width * scale), this.originalWidth - newCrop.x);
    newCrop.height = Math.min(Math.round(this.height * scale), this.originalHeight - newCrop.y);
    return newCrop;
  }
}

export class Giftcard {
  @observable code: string;
  @observable amount: number;
  @computed get isValid() {
    return this.code !== "" && this.amount > 0;
  }

  constructor(code = "", amount = 0) {
    this.code = code;
    this.amount = amount;
  }
}

export class Coupon {

  @observable code: string;
  @observable price: number;
  @observable type: string;
  @computed get isValid() {
    return this.code !== "" && this.price > 0;
  }

  constructor(code = "", price = 0, type = "") {
    this.code = code;
    this.price = price;
    this.type = type;
  }
}

export class Customer {

  @observable corporation: string;

  @observable firstName: string;

  @observable lastName: string;

  @observable street: string;

  @observable postalCode: string;

  @observable place: string;

  @observable houseNumber: string;

  @observable country: string;

  @observable email: string;

  @observable telephone: string;

  @observable mobile: string;

  @observable subscribed: boolean;

  @observable invoiceCorporation: string;

  @observable invoiceFirstName: string;

  @observable invoiceLastName: string;

  @observable invoiceStreet: string;

  @observable invoicePostalCode: string;

  @observable invoicePlace: string;

  @observable invoiceHouseNumber: string;

  @observable invoiceCountry: string;
}
