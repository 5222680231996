import { StaticQuery, graphql } from "gatsby";
import * as React from "react";

import { SquidexTexts, SquidexConfig } from 'generated/gatsbyApiTypes';
import { getLanguageValue } from 'utils';

export interface IShopHeaderProps {
  location: {
    pathname: string;
  }
}

export interface QueryData {
  squidexTexts: SquidexTexts;
  squidexConfig: SquidexConfig;
}

export default class Shopheader extends React.Component<IShopHeaderProps> {
  render() {
    return (
      <StaticQuery
        query={graphql`{
              squidexTexts(identity: {eq: "squidextexts"}) {
                pageShopCart {
                  de
                  nl
                  en
                }
                pageShopCustomerDetails {
                  de
                  nl
                  en
                }
                pageShopOrder {
                  de
                  nl
                  en
                }
              }
              squidexConfig(identity: {eq: "config"}) {
                pageCartSlug {
                  iv
                }
                pageCustomerInfoSlug {
                  iv
                }
                pageOrderSlug {
                  iv
                }
              }
            }`}
        render={(data: QueryData) => (
          <>
          {this.renderActiveHeader(data)}
          <div className="dt dt--fixed mt3">
            {this.renderSteps(data)}
          </div>
          </>
        )}
      />
    )
  }
  renderActiveHeader = (data: QueryData) => {
    const path = trimEndingSlash(this.props.location.pathname);
    let text = "";
    if (path === data.squidexConfig.pageCartSlug.iv.toLowerCase()) {
      text = getLanguageValue(data.squidexTexts.pageShopCart);
    }
    if (path === data.squidexConfig.pageCustomerInfoSlug.iv.toLowerCase()) {
        text = getLanguageValue(data.squidexTexts.pageShopCustomerDetails)
    }
    if (path === data.squidexConfig.pageOrderSlug.iv.toLowerCase()) {
        text = getLanguageValue(data.squidexTexts.pageShopOrder)
    }
    return <h1 className="dn-ns">{text}</h1>
  }
  renderSteps = (data: QueryData) => {
    const steps = [
      { href: data.squidexConfig.pageCartSlug.iv.toLowerCase(), text: getLanguageValue(data.squidexTexts.pageShopCart) },
      { href: data.squidexConfig.pageCustomerInfoSlug.iv.toLowerCase(), text: getLanguageValue(data.squidexTexts.pageShopCustomerDetails) },
      { href: data.squidexConfig.pageOrderSlug.iv.toLowerCase(), text: getLanguageValue(data.squidexTexts.pageShopOrder) }
    ]
    return steps.map(({ href, text }, i) => this.renderSection(href, i + 1, text, !(i == 0 || i == steps.length - 1)));
  }
  renderSection(href: string, num: number, text: string, isMid: boolean) {
    const border = isMid ? "bt bb" : "ba";
    if (href === trimEndingSlash(this.props.location.pathname)) {
      return (
        <div key={`steps-${num}`} className={`dtc tc pa2 relative ${border} b--black-10 triangle `}>
          <div className="dib br-100 near-white bg-theme w2 lh-copy tc f4">{num}</div>
          <h3 className="dn dib-ns ml2 mv0 f4">{text}</h3>
        </div>
      )
    }
    return (
      <a key={`steps-${num}`} className={`dtc tc pa2 relative ${border} b--black-10 triangle link black`} href={href}>
        <div className="dib br-100 white bg-black-10 w2 lh-copy tc f4">{num}</div>
        <h3 className="dn dib-ns ml2 mv0 f4 gray">{text}</h3>
      </a>
    )
  }
}

const trimEndingSlash = (s: string) => {
  if (s[s.length - 1] === "/") {
    return s.substr(0, s.length - 1);
  }
  return s;
}
