import { action, observable, set } from "mobx";

import { IApiClient } from "../../side-effects";

import { Order, OrderItem, Coupon, Customer, Giftcard, Crop } from "./order";
import { IStore } from "../interfaces";
import autoStore from "../autoStore";

export class OrderStore implements IStore {
  @observable public current: Order;
  @observable public currentItem: OrderItem;
  @observable public fetching: boolean;
  public key = "OrderStore";
  public version = process.env.GATSBY_BUILD;

  private apiClient: IApiClient;
  constructor(apiClient: IApiClient) {
    this.current = new Order();
    this.currentItem = new OrderItem();
    this.apiClient = apiClient;
    autoStore(this);
  }
  @action public clearItem() {
    this.currentItem = new OrderItem();
  }
  @action public clearAll() {
    this.current = new Order();
    this.currentItem = new OrderItem();
  }
  @action public submitItem() {
    let isEdit = false;
    for (let item of this.current.items) {
      if (this.currentItem === item) {
        isEdit = true;
        break;
      }
    }
    if (!isEdit) {
      this.current.items.push(this.currentItem);
    }
    this.currentItem = new OrderItem();
  }
  @action public deleteItem(item: OrderItem) {
    const index = this.current.items.indexOf(item);
    if (index > -1) {
      this.current.items.splice(index, 1);
    }
  }
  @action public checkGiftcard(code: string) {
    this.fetching = true;
    return this.apiClient.getGiftcard(code).then(
      ({ result }) => {
        this.fetching = false;
        this.current.giftCard = result;
        return result;
      }
    )
  }
  @action public checkCoupon(code: string) {
    this.fetching = true;
    return this.apiClient.getCoupon(code).then(
      ({ result }) => {
        this.fetching = false;
        this.current.coupon = result;
        return result;
      }
    )
  }

  @action public getCustomPrice() {
    if (this.currentItem.isCustomSize) {
      this.fetching = true;
      return this.apiClient.getCustomSizePrice(this.currentItem.material.id, this.currentItem.height, this.currentItem.width)
        .then(
          ({ result }) => {
            this.fetching = false;
            this.currentItem.rawPrice = result.price;
            return result.price;
          }
        )
    }
  }

  @action public placeOrder(returnUrl: string, cancelUrl: string) {
    this.fetching = true;
    return this.apiClient.placeOrder(this.current, returnUrl, cancelUrl).then(
      ({result}) => {
        if (result && result.result) {
          this.current.id = result.result.orderId;
        }
        this.fetching = false;
        return result;
      }
    )
  }

  public loadFromStorage(store: OrderStore) {
    set(this.current, store.current);
    this.current.coupon = new Coupon();
    set(this.current.coupon, store.current.coupon);
    this.current.customer = new Customer();
    set(this.current.customer, store.current.customer);
    this.current.giftCard = new Giftcard();
    set(this.current.giftCard, store.current.giftCard);
    this.current.items = [];
    store.current.items.map((oldItem) => {
      this.current.items.push(this.loadOrderItem(oldItem));
    })
    this.currentItem = this.loadOrderItem(store.currentItem);
  }

  loadOrderItem = (oldItem: OrderItem) => {
    const item = new OrderItem();
    set(item, oldItem);
    item.crop = new Crop();
    set(item.crop, oldItem.crop)
    // item.material = new Material();
    // set(item.material, oldItem.material);
    // item.format = new Format();
    // set(item.format, oldItem.format);
    // item.options = [];
    // oldItem.options.map(oldOption => {
    //   const option = new Option();
    //   set(option, oldOption);
    //   item.options.push(option);
    // })
    return item;
  }
}
