
import * as React from "react";

export interface CheckboxProps {
    horizontal?: boolean;
    className?: string;
    label?: React.ReactChild;
    checked?: boolean;
    error?: boolean;
    onChange?: (value: boolean) => void;
}

export default class Checkbox extends React.Component<CheckboxProps> {
    render() {
        let label;
        if (this.props.label && (typeof(this.props.label) === "string" || typeof(this.props.label) === "number")) {
          label = this.props.label ? <label className="db tl ml2">{this.props.label}</label> : undefined;
        } else {
          label = this.props.label ? this.props.label : undefined;
        }
        const cbCs = "pa2 db";
        const cs = this.props.className ? this.props.className : "";
        const cbStyle = this.props.error ? { outline: "1px solid red" } : undefined;
        if (!this.props.horizontal) {
            return (
                <div className={"flex items-center mb2 w-100 " + cs}>
                    <input className={`${cbCs}`} style={cbStyle} type="checkbox" defaultChecked={this.props.checked} onChange={this.handleChange} />
                    {label}
                </div>
            )
        }
        return (
            <div className="flex-ns dib items-center mb2 w-100">
                <div className="fl w-third-ns w-100 mb2">
                    {label}
                </div>
                <div className="fl w-two-thirds-ns w-100 mb2">
                    <input className={`${cbCs} ${cs}`} type="checkbox" defaultChecked={this.props.checked} onChange={this.handleChange} />
                </div>
            </div>
        )
    }
    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.onChange) {
            this.props.onChange(e.target.checked);
        }
    }
}


