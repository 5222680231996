import { StaticQuery, graphql } from "gatsby"
import * as React from "react";
import { inject, Observer } from "mobx-react";
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import { OrderStore, VisitorStore, ShopStore, Material, Format, ImageGallery } from "stores";
import { SELECT_IMAGE_EVENT } from "utils/constants";
import { Lightbox, IGalleryFilter } from 'components';
import { SquidexGalleryItem, SquidexTexts, SquidexConfig } from 'generated/gatsbyApiTypes';

import GalleryItemsContainer from './galleryItemsContainer';
import { getLanguageValue } from 'utils';

export interface GalleryProps {
  nodes: SquidexGalleryItem[]
  single?: boolean;
  materialId?: number;
  gallery?: ImageGallery;
  orderStore?: OrderStore;
  shopStore?: ShopStore;
  visitorStore?: VisitorStore;
  navigate: (route: string) => void;
  filters?: IGalleryFilter[];
}



export interface GalleryState {
  currentImage: number;
}

@inject("orderStore", "shopStore", "visitorStore")
export default class Gallery extends React.Component<GalleryProps, GalleryState> {
  private shopSlug: string;
  constructor(props: GalleryProps) {
    super(props);
    this.state = {
      currentImage: undefined
    }
  }
  render() {
    return <StaticQuery
      query={graphql`
      {
        squidexTexts(identity:{eq: "squidextexts"}) {
          btnGalleryitemOrder {
            de
            nl
            en
          }
          txtOnMaterial {
            de
            nl
            en
          }
          txtForPrice {
            de
            nl
            en
          }
          txtClickToEnlarge {
            de
            nl
            en
          }
        }
        squidexConfig(identity: {eq: "config"}) {
          pageShopSlug {
            iv
          }
        }
      }`}
      render={
        (data: {squidexTexts: SquidexTexts, squidexConfig: SquidexConfig}) => {
          const nodes = this.props.nodes
            .filter(n => {
              const hasImage = n.image && n.image.childImageSharp;
              if (this.props.filters) {
                return hasImage && this.props.filters.map((f) => {
                  const tags = getLanguageValue(n.tags);
                  return n.tags !== null && tags.findIndex((t) => t.name === f.name && t.value === f.value) !== -1;
                }).reduce((acc, next) => acc && next, true);
              }
              return hasImage;
            })
          const images = nodes.map(
            (node) => node.image && node.image.childImageSharp 
              ? node.image.childImageSharp.fluid
              : undefined
          ).filter(i => !!i);
          this.shopSlug = data.squidexConfig.pageShopSlug.iv.toLowerCase();
          return (
            <Observer>{() => {
              return (
              <>
                <GalleryItemsContainer
                  single={this.props.single}
                  loading={!this.props.shopStore.current}
                  forPriceText={getLanguageValue(data.squidexTexts.txtForPrice)}
                  getMaterial={this.getMaterial}
                  getFormat={this.getFormat}
                  nodes={nodes}
                  onOrderClick={this.handleOrder}
                  onMaterialText={getLanguageValue(data.squidexTexts.txtOnMaterial)}
                  orderButtonText={getLanguageValue(data.squidexTexts.btnGalleryitemOrder)}
                  // orderButtonText="Material und GröBe wählen"
                  clickToEnlargeText={getLanguageValue(data.squidexTexts.txtClickToEnlarge)}
                  onImageClick={this.handleItemImageClick}
                  gallery={this.props.gallery}
                />
                <Lightbox images={images} imageIndex={this.state.currentImage} />
              </>
            )}}</Observer>
          )
        }}
    />
  }
  handleItemImageClick = (node: SquidexGalleryItem, i: number) => {
    // const index = this.props.nodes.indexOf(node);
    this.setState({ currentImage: i });
  }
  getMaterial = () => {
    if (this.props.materialId) {
      var pageMaterial = this.props.shopStore.getMaterial(this.props.materialId);
      if (pageMaterial && !pageMaterial.customSizes) {
        return pageMaterial;
      }
    }
    if (this.props.visitorStore.current.hasSeenMaterials) {
      const lastMaterialId = this.props.visitorStore.current.lastMaterialId;
      const seenMaterialIds = this.props.visitorStore.current.seenMaterialIds;
      const lastMaterial = this.props.shopStore.getMaterial(lastMaterialId);
      if (Math.random() < 0.6 && lastMaterial && !lastMaterial.customSizes) {
        return this.props.shopStore.getMaterial(lastMaterialId);
      } else {
        let material: Partial<Material> = { customSizes: true };
        let count = 0;
        while (material && material.customSizes && count < 10) {
          const index = Math.floor(Math.random() * seenMaterialIds.length);
          material = this.props.shopStore.getMaterial(seenMaterialIds[index]);
        }
        if (material && !material.customSizes) {
          return material as Material;
        }
      }
    }
    const materials = this.props.shopStore.current.materials;
    if (materials.length == 0) {
      return undefined;
    }
    const index = Math.floor(Math.random() * materials.length);
    return materials[index];
  }
  getFormat = (node: any, material?: Material) => {
    if (!material) {
      return undefined;
    }
    const image = node.image && node.image.childImageSharp ? node.image.childImageSharp.fluid : undefined;
    const aspectRatio = image ? image.aspectRatio : 1;
    if (material.customSizes) {
      const scale = this.props.shopStore.customFormatScales[Math.floor(Math.random() * this.props.shopStore.customFormatScales.length)]
      const width = Math.floor(scale * aspectRatio);
      const height = Math.floor(scale);

      return fromPromise<Format>(this.props.shopStore.generateCustomFormat(material.id, width, height));
    }
    return fromPromise<Format>(new Promise((res, rej) => res(material.formats.reduce((best, next) => {
      if (!best || Math.abs(aspectRatio - best.width / best.height) > Math.abs(aspectRatio - next.width / next.height)) {
        return next;
      } else {
        return best;
      }
    }))));
  }
  handleOrder = (galleryItem: SquidexGalleryItem, material?: Material, format?: IPromiseBasedObservable<Format>) => {
    const originalImg = galleryItem.image.childImageSharp.original;
    const resizedImg = galleryItem.image.childImageSharp.fluid;
    const store: OrderStore = this.props.orderStore;
    store.currentItem.srcImage = window.location.origin + originalImg.src;
    store.currentItem.image = window.location.origin + resizedImg.src;
    store.currentItem.effect = "geen";
    store.currentItem.crop.originalHeight = originalImg.height;
    store.currentItem.crop.originalWidth = originalImg.width;
    if (material) {
      store.currentItem.material = material;
      if (format.state === "fulfilled") {
        if (material.customSizes) {
          store.currentItem.width = format.value.width;
          store.currentItem.height = format.value.height;
        } else {
          store.currentItem.format = format.value;
        }
        store.currentItem.filter = format.value.houding;
        store.currentItem.rawPrice = format.value.price;
      }
      if (material.options && material.options.length > 0) {
        store.currentItem.options = [material.options[0]];
      } else {
        store.currentItem.options = [];
      }
    }
    store.currentItem.imageGallery = this.props.gallery;
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(SELECT_IMAGE_EVENT, {
      galleryItem,
      material,
      format
    });

    setTimeout(() => { this.props.navigate(this.shopSlug) }, 200);
  }

}


