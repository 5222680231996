
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"
import { inject } from "mobx-react";
import { captureException } from "@sentry/browser";

import { UIStore, VisitorStore } from "stores";
import { CAUGHT_EXCEPTION_EVENT } from "utils/constants";
import { getLanguageValue } from 'utils';
import { SquidexTexts } from 'generated/gatsbyApiTypes';


@inject("uiStore", "visitorStore")
export default class ErrorHandler extends React.Component<any> 
{
  private errorHeader: string;
  private errorBody: string;
  componentWillMount() {
    if (typeof(window) !== "undefined") {
      window.onerror = this.handleError;
    }
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
        query ErrorHandlerQuery {
          squidexTexts(identity: {eq: "squidextexts"}) {
            txtGenericErrorHeader {
              de
              nl
              en
            }
            txtGenericErrorBody {
              de
              nl
              en
            }
          }
        }    
            `}
        render={(data: {squidexTexts: SquidexTexts}) => {
          this.errorBody = getLanguageValue(data.squidexTexts.txtGenericErrorBody);
          this.errorHeader = getLanguageValue(data.squidexTexts.txtGenericErrorHeader);
          return this.props.children;
        }} />
    )
  }
  handleError = (evt: string|Event) => {
    const store: UIStore = this.props.uiStore;
    store.showModal(this.errorHeader, this.errorBody);
    if (typeof(evt) !== "string") {
      evt.preventDefault();
    }
    captureException(evt);
    const visitorStore: VisitorStore = this.props.visitorStore;
    visitorStore.logEvent(CAUGHT_EXCEPTION_EVENT, evt);        
    return true;
  }
}
