import { ShopStore, OrderStore } from "stores";

export const getOrderTotals = (shopStore: ShopStore, store: OrderStore) => {
  const subTotal = store.current.subTotal;
  const discount = store.current.discount;
  const deliverCost = shopStore.calcDeliverCost(subTotal);
  const giftAmount = store.current.cardAmount > (subTotal + deliverCost - discount) ? (subTotal + deliverCost - discount) : store.current.cardAmount;
  const total = subTotal - discount + deliverCost - giftAmount; 
  return {
    subTotal,
    discount,
    deliverCost,
    giftAmount,
    total
  }
}
