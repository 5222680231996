import * as React from "react";
import Img from "gatsby-image";

import { SquidexProductPage } from "generated/gatsbyApiTypes"
import { getLanguageValue, getFixedImageSharp, getFluidImageSharp } from "utils";
import { Button, ProductQualities } from "components";

export interface ProductPageCollectionItemProps {
  node: SquidexProductPage;
  btnText: string;
  onClick: (node: SquidexProductPage) => void;
}

export default class ProductPageCollectionItem extends React.Component<ProductPageCollectionItemProps> {
  render() {
    const node = this.props.node;
    const img = node.headerImage ? getFluidImageSharp(node.headerImage) : undefined;
    if (!img) {
      return null;
    }
    const title = node && node.title ? getLanguageValue(node.title) : "";
    const tagline = node && node.tagline ? getLanguageValue(node.tagline) : ""
    return (
      <div className="inline-flex flex-column w-100 w-49-ns w-32-l mb2 mr-2pct">
        <a className="w-100 dim" onClick={this.handleClick}><Img fluid={img} alt={title} style={{"height": "250px"}} /></a>
        <h4 className="ma0 mt2">{title}</h4>
        <p className="gray f6">{tagline}</p>
        <ProductQualities className="flex-grow-1" nodes={getLanguageValue(node.qualities).filter((_, i) => i < 3)}/>
        <Button className="self-center" label={this.props.btnText} inversed={true} onClick={this.handleClick} />
      </div>
    )
  }
  handleClick = () => {
    this.props.onClick(this.props.node);
  }
}


