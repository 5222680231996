import React from "react"
import { Provider } from "mobx-react"

import { ApiClient } from "./src/side-effects"
import { UIStore, ShopStore, OrderStore, VisitorStore } from "./src/stores"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
    const apiClient = new ApiClient(process.env.GATSBY_HOST, process.env.GATSBY_API_URL, process.env.GATSBY_SHOP_ID);
    const shopStore = new ShopStore(apiClient);
    const orderStore = new OrderStore(apiClient);
    const uiStore = new UIStore()
    const visitorStore = new VisitorStore()
    const stores = {
        shopStore,
        orderStore,
        uiStore,
        visitorStore
    }
    return (
        <Provider {...stores}>{element}</Provider>
    )
}
