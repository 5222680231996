

import * as React from "react";
import { slide as Menu } from "react-burger-menu"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

import { SquidexMenuItem } from 'generated/gatsbyApiTypes';

export interface MobileMenuPresentationProps {
  nodes: SquidexMenuItem[];
  tagline: string;
  title: string;
}

export interface MobileMenuPresentationState {
  menuOpen: boolean;
  openSubmenu: number;
}

export default class MobileMenuPresentation extends React.Component<MobileMenuPresentationProps, MobileMenuPresentationState> {
  constructor(props: MobileMenuPresentationProps) {
    super(props);
    this.state = {
      menuOpen: false,
      openSubmenu: -1
    }
  }
  render() {
    return <nav className={"bg-theme db dn-ns pa2 pa3-ns shadow-2 w-100 z-5"}>
    <div className="center flex items-center justify-between justify-start-ns mwPage h-100">         
      
    <a className="dim f3 dib dn-ns mr0 mr5-ns ml3 ml0-ns white f-logo" href="/" title="Home">{this.props.title}</a>
        <div id="nav-items" className="db dn-ns relative white mr3 z-1">
          <Menu
            isOpen={this.state.menuOpen}
            onStateChange={this.openMenu}
            customBurgerIcon={<FontAwesomeIcon icon={faBars} />}
            styles={burgerMenuStyles}
            right={true}
          >
            {
              this.props.nodes.map(this.renderMenuItem)
            }
          </Menu>
        </div>
      </div>
    </nav>
  }
  renderMenuItem = (node: SquidexMenuItem, i: number) => {
    // if (!node.submenu || node.submenu.length === 0) {
      return <a href={node.href.iv.toLowerCase()} key={`menu-item-m-${i}`} onClick={this.closeMenu} className="white flex pa2 w-100">{node.name.iv}</a>
    // }
    // return (
    //   <div onClick={this.openSubmenu(i)} key={`menu-item-m-${i}`} className="white flex pa2 w-100">
    //     <span>{node.name.iv}</span>
    //     {
    //       this.state.openSubmenu === i
    //         ? node.submenu.map((subNode, j) => this.renderSubmenu(subNode, i, j))
    //         : null
    //     }
    //   </div>
    // )
  }
  // renderSubmenu = (node: SquidexMenuItem, nodeIndex: number, submenuIndex: number) => {
  //   let className = "white l flex pa2 w-100 mr3"
  //   className += submenuIndex === 0 ? " mt2" : ""
  //   return (<a
  //     onClick={this.closeMenu}
  //     href={node.href.iv.toLowerCase()}
  //     key={`menu-item-m-${nodeIndex}-${submenuIndex}`}
  //     className={className}>
  //     {node.name.iv}
  //   </a>)
  // }
  // openSubmenu = (i: number) => () => {
  //   this.setState({ openSubmenu: this.state.openSubmenu === i ? undefined : i });
  // }
  openMenu = (state: { isOpen: boolean }) => {
    this.setState({ menuOpen: state.isOpen });
  }
  closeMenu = () => {
    this.setState({ menuOpen: false });
  }
}

const burgerMenuStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '36px',
    height: '30px',
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: "0px"
  },
  bmMenu: {
    background: "#222",
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 1)'
  }
}



