
import * as React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby"

import { Site, SquidexConfig, SquidexCompany } from 'generated/gatsbyApiTypes';

export interface SEOProps {
  subTitle?: string;
}

export interface QueryData
{
  site: Site;
  squidexConfig: SquidexConfig;
  squidexCompany: SquidexCompany;
}

export default class SEO extends React.Component<SEOProps> {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
           site { 
             siteMetadata {
              siteUrl 
            }
          }
            squidexConfig(identity: { eq: "config"}) {
              title {
                iv
              }
              description {
                iv
              }
            }
            squidexCompany(identity: { eq: "company"}) {
              operatingName {
                iv
              }
              name {
                iv
              }
              adres1 {
                iv
              }
              adres2 {
                iv
              }
              phone {
                iv
              }
              email {
                iv
              }
            }
          }
          `}
        render={(data: QueryData) => {
          const schema = [{
            "@context": "http://schema.org",
            "@type:": "WebSite",
            url: data.site.siteMetadata.siteUrl,
            name: data.squidexConfig.title.iv,
          }, {
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "name": data.squidexCompany.operatingName.iv,
            "telephone": data.squidexCompany.phone.iv,
            "email": data.squidexCompany.email.iv,
            "address": {
              "@type": "PostalAddress",
              "streetAddress": data.squidexCompany.adres1.iv,
              "postalCode": data.squidexCompany.adres2.iv
            }
          }];
          const title = this.props.subTitle 
            ? data.squidexConfig.title.iv + " - " + this.props.subTitle
            : data.squidexConfig.title.iv
          return (
            <Helmet>
              {/* General tags */}
              <title>{title}</title>
              <meta name="description" content={data.squidexConfig.description.iv} />

              {/* OpenGraph tags */}
              <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={data.squidexConfig.description.iv} />

              {/* Schema.org tags */}
              <script type="application/ld+json">{JSON.stringify(schema)}</script>
            </Helmet>
          )
        }} />
    )
  }
}
