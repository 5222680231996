
import * as React from "react";

import { hashString } from "../../utils";
import { SquidexProductPageQualitiesDe } from "../../generated/gatsbyApiTypes"

import ProductQuality from './productQuality';

export interface ProductQualitiesContainerProps {
  nodes: SquidexProductPageQualitiesDe[];
  className?: string;
}

export default class ProductQualitiesContainer extends React.Component<ProductQualitiesContainerProps> {
  render() {
    return (
      <div className={this.props.className}>
        {
          this.props.nodes.map((node, i) =>
            <ProductQuality
              node={node}
              key={`quality-${hashString(node.text)}`}
            />
          )
        }
      </div>
    )
  }
}


