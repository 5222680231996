

import * as React from "react";

import { VisitorStore } from 'stores';
import Button from '../button';
import Checkbox from '../checkbox';
import { inject, observer } from 'mobx-react';

export interface GdprReminderPresentationProps {
  open: boolean;
  privacyPolicyError: boolean;
  txtPrivacyModalHeader: string;
  txtPrivacyModalIntro: string;
  txtPrivacyPolicy: string;
  txtAnalyticsInformation: string;
  privacyPolicyHref: string;
  btnConfirmText: string;
  onPrivacyPolicyChange: (consent: boolean) => void;
  onAnalyticsConsentChange: (consent: boolean) => void;
  onConfirm: () => void;
  visitorStore?: VisitorStore;
}

@inject("visitorStore")
@observer
export default class GdprReminderPresentation extends React.Component<GdprReminderPresentationProps> {

  render() {
    const modalCs = this.props.open ? "active modal" : "modal"
    return (
      <div className={modalCs}>
        <div className="backdrop" />
        <div className="modal content bg-near-white" style={{width: "32rem"}}>
          <div className="modal header pa3 f4 w100">
            {this.props.txtPrivacyModalHeader}
          </div>
          <div className="modal body pa3 w100">
            {this.props.txtPrivacyModalIntro}
            <Checkbox
              error={this.props.privacyPolicyError}
              className="mt2"
              label={<a className="ml2" target="_blank" href={this.props.privacyPolicyHref}>{this.props.txtPrivacyPolicy}</a>}
              onChange={this.props.onPrivacyPolicyChange} />
            <Checkbox
              label={this.props.txtAnalyticsInformation}
              checked={this.props.visitorStore.current.hasConsentedToAnalytics}
              onChange={this.props.onAnalyticsConsentChange} />
          </div>
          <div className="modal footer pa3 w100 tc">
            <Button inversed={!this.props.visitorStore.current.hasConsentedToGDPR} color="theme" label={this.props.btnConfirmText} onClick={this.props.onConfirm} />
          </div>
        </div>
      </div>
    )
  }
}


