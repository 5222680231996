import { SquidexAsset } from 'generated/gatsbyApiTypes';
import { FluidObject, FixedObject } from 'gatsby-image';

export type VoidFunc = (args: any[]) => void;
export type VoidPromiseFunc = (args: any[]) => Promise<void>;

export class Debounced {
  private timeout: number;
  private func: VoidFunc | VoidPromiseFunc;
  private timer: any;
  public set = (func: VoidFunc | VoidPromiseFunc, timeout: number) => {
    this.timeout = timeout;
    this.func = func;
    this.call();
  }

  public call = () => {
    if (typeof(this.timer) !== "undefined") {
      window.clearTimeout(this.timer);
    }
    this.timer = window.setTimeout(this.func, this.timeout);
  }
}

export function hashString (input: string) {
  var hash = 0;
  if (input.length == 0) {
      return hash;
  }
  for (var i = 0; i < input.length; i++) {
      var char = input.charCodeAt(i);
      hash = ((hash<<5)-hash)+char;
      hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

interface SquidexMultiLanguage<A,B,C> {
  de?: A;
  nl?: B;
  en?: C;
}

export function getLanguageValue<A,B,C>(obj: SquidexMultiLanguage<A,B,C>, language:string = process.env.GATSBY_LANGUAGE): A {
  return obj ? (obj as any)[language] as A : undefined;
}

export function getFluidImageSharp(obj: SquidexAsset)
{
  if (obj && obj.childImageSharp && obj.childImageSharp.fluid) {
    return obj.childImageSharp.fluid as FluidObject;
  }
  return undefined;
}

export function getFixedImageSharp(obj: SquidexAsset)
{
  if (obj && obj.childImageSharp && obj.childImageSharp.fixed) {
    return obj.childImageSharp.fixed as FixedObject;
  }
  return undefined;
}
