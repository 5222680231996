
import * as React from "react";
import { StaticQuery, graphql } from "gatsby"

import { getLanguageValue } from 'utils';
import { SquidexTexts, SquidexConfig, SquidexCompany } from 'generated/gatsbyApiTypes';

import FooterPresentation from './presentation';

export default () => (
  <StaticQuery
    query={graphql`
          {      
            squidexTexts(identity: {eq: "squidextexts"}) {
              txtContact {
                de
                nl
                en
              }
            }
            squidexConfig(identity: { eq: "config"}) {
              title {
                iv
              }
              footerLinksTitle {
                de
                nl
                en
              }
              footerLinks {
                de {
                  label
                  href
                }
                nl {
                  label
                  href
                }
                en {
                  label
                  href
                }
              }
            }
            squidexCompany(identity: { eq: "company"}) {
              operatingName {
                iv
              }
              name {
                iv
              }
              adres1 {
                iv
              }
              adres2 {
                iv
              }
              phone {
                iv
              }
              email {
                iv
              }
            }
          }
          `}
    render={(data: {squidexCompany: SquidexCompany, squidexConfig: SquidexConfig, squidexTexts: SquidexTexts}) => (
      <FooterPresentation 
        company={data.squidexCompany}
        footerLinks={getLanguageValue(data.squidexConfig.footerLinks)}
        footerLinksTitle={getLanguageValue(data.squidexConfig.footerLinksTitle)}
        txtContact={getLanguageValue(data.squidexTexts.txtContact)}
      />
    )} />
)

