
import * as React from "react";

export interface ButtonProps {
    className?: string;
    color?: string;
    textColor?: string;
    inversed?: boolean;
    label: string;
    href?: string;
    size?: number;
    onClick?: (t: React.MouseEvent<HTMLButtonElement>) => void;
}

export default class Button extends React.Component<ButtonProps> {
    render() {
        const color = this.props.color ? this.props.color : "gray";
        const textColor = this.props.textColor ? this.props.textColor : this.props.inversed ? color : "white";
        const bg = this.props.inversed ? "bg-white" : "bg-" + color;
        const bColor = this.props.inversed ? "b--" + color : "";
        const ba = this.props.inversed ? "ba" : "bn";
        const className = this.props.className ? this.props.className + " " : ""
        const size = "f" + (this.props.size ? this.props.size : this.getFontSizeForText());
        const cs = `${className} tc ${size} link dim pointer br2 dib ph4 fw5 pv2 ${textColor} ${ba} ${bg} ${bColor}`;
        const href = this.props.href;
        return React.createElement(href ? "a" : "button", {className: cs, onClick: this.props.onClick, href}, this.props.label)
    }
    getFontSizeForText = () => {
      const defaultFontSize = 4;
      let fontSize = defaultFontSize;
      if (this.props.label.length > 8) {
        fontSize++;
      }
      if (this.props.label.length > 16) {
        fontSize++;
      }
      if (this.props.label.length > 24) {
        fontSize++;
      }
      return fontSize;
    }
}


