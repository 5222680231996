

import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faEnvelope, faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'

import { SquidexCompany, SquidexConfigFooterLinksDe } from 'generated/gatsbyApiTypes';

export interface FooterPresentationProps {
  company: SquidexCompany
  txtContact: string
  footerLinksTitle: string;
  footerLinks: SquidexConfigFooterLinksDe[]  
}

export default class FooterPresentation extends React.Component<FooterPresentationProps> {

  render() {
    return (
      <footer className="bt b--footer bg-footer footer pa3">
        <div className="mwPage center white flex flex-column flex-row-ns">
          <article className="w-50-ns mb4 relative pl2">
            <h3 className="white mt0 mb2">{this.props.txtContact}</h3>
            <div className="db w-100 relative mb2">
              <FontAwesomeIcon icon={faPhoneAlt} color="white" size="sm" className="relative" style={{left: "-20px"}}/>
              <span className="f6 fw6 dib relative" style={{left: "-11px"}}>{this.props.company.phone.iv}</span>
            </div>
            <div className="db w-100 relative mb4">
              <FontAwesomeIcon icon={faEnvelope} color="white" size="sm" className="relative" style={{left: "-20px"}}/>
              <span className="f6 fw6 dib relative" style={{left: "-11px"}}>{this.props.company.email.iv}</span>
            </div>

            <div className="db w-100 relative mb2">
              <FontAwesomeIcon icon={faMapMarkerAlt} color="white" size="sm" className="relative" style={{left: "-20px"}}/>
              <span className="f6 fw6 dib relative" style={{left: "-11px"}}>{this.props.company.operatingName.iv}</span>
            </div>
            <span className="f7 f6-l db mb2">{this.props.company.adres1.iv}</span>
            <span className="f7 f6-l">{this.props.company.adres2.iv} </span>
          </article>
          <article className="w-50-ns pl2 ph0-l mb4">
            <h3 className="white pa0 ma0 mb2">{this.props.footerLinksTitle}</h3>
            {this.props.footerLinks.map(renderFooterlink)}
          </article>
        </div>
        <div className="mwPage center dt dt--fixed w-100 white" >
          <div className="dn dtc-ns v-mid">
            <p className="f7 dib pr3 mb3">
              Copyright © {this.props.company.name.iv}
            </p>
          </div>
        </div>
        <div className="db dn-ns">
          <p className="f7 tc">
            Copyright © {this.props.company.name.iv}
          </p>
        </div>
      </footer>
    )
  }
}

const renderFooterlink = (footerLink: SquidexConfigFooterLinksDe, i: number) => {
  return (
    <a key={`footerLink-${i}`} 
       className="f7 dim db pv1 link white" 
       href={footerLink.href.toLowerCase()}>
         {footerLink.label}
    </a>)
}

