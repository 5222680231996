import * as React from "react";
import Img from "gatsby-image";

import { SquidexSpotlightItem } from "generated/gatsbyApiTypes"
import { getLanguageValue, getFluidImageSharp } from "utils";

export interface SpotlightItemProps {
  node: SquidexSpotlightItem;
  reverse: boolean;
}

export default class SpotlightItem extends React.Component<SpotlightItemProps> {
  render() {
    const text = getLanguageValue(this.props.node.text).html;
    const img = getFluidImageSharp(this.props.node.image);
    let className = "bt-ns pv2 b--light-gray flex flex-column-reverse";
    className += this.props.reverse ? " flex-row-reverse-ns" : " flex-row-ns";
    return (
      <section className={className}>
        <div className="w-100 w5-ns self-stretch">
          <Img fluid={img} />
        </div>
        <div className={`w-100 p${this.props.reverse ? 'r': 'l'}3-ns self-center markdown`} dangerouslySetInnerHTML={{ __html: text }} />
      </section>
    )
  }
}


