
import * as React from "react";

import { SquidexSpotlightItem } from 'generated/gatsbyApiTypes';

import SpotlightsContainer from './spotlightsContainer';

export interface SpotlightsProps {
  nodes: SquidexSpotlightItem[];
}

export default class Spotlights extends React.Component<SpotlightsProps> {
  render() {
    return (<SpotlightsContainer nodes={this.props.nodes} />)
  }
}


