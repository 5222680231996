import axios, { AxiosResponse } from "axios";
import { action, observable, set } from "mobx";

import { IStore } from "../interfaces";
import autoStore from "../autoStore";
import { Visitor } from "./visitor";
import { NO_OP } from "../../utils/constants";

export interface IEventQueueItem {
  eventName: string;
  eventData?: any;
}

export class VisitorStore implements IStore {
  public key = "VisitorStore";
  public version = process.env.GATSBY_BUILD;
  private queue: IEventQueueItem[] = [];
  private analyticsActive = false;
  private uaKey = process.env.GATSBY_UA_KEY;
  private awKey = process.env.GATSBY_AW_KEY;
  private gKey = process.env.GATSBY_G_KEY;
  private gtag: any = NO_OP;
  @observable current: Visitor;
  constructor() {
    this.current = new Visitor();
    autoStore(this);
    if (this.current.hasConsentedToGDPR) {
      this.startAnalytics();
    }
  }
  @action public clear() {
    this.current = new Visitor();
  }
  @action public spot(id: number) {
    this.current.spot(id);
  }
  @action public async startAnalytics() {
    if (typeof (window) !== "undefined") {
      this.loadExternalScripts();
      window.dataLayer = window.dataLayer || [];
      this.gtag = function () { window.dataLayer.push(arguments); }
      this.gtag('js', new Date());
      this.gtag("config", this.uaKey, {
        anonymize_ip: true,
        cookie_expires: 0
      });
      this.gtag("config", this.awKey, {
        anonymize_ip: true,
        cookie_expires: 0
      });
      this.gtag("config", this.gKey, {
        anonymize_ip: true,
        cookie_expires: 0
      });
      this.analyticsActive = true;
      if (this.queue.length > 0) {
        for (const item of this.queue) {
          this.gtag("event", item.eventName, item.eventData ? {
            value: item.eventData
          } : undefined);
        }
      }
    }
  }
  public logEvent(eventName: string, eventData?: any) {
    if (this.analyticsActive) {
      this.gtag("event", eventName, eventData ? {
        value: eventData
      } : undefined);
    } else {
      this.queue.push({ eventName, eventData })
    }
  }
  public loadFromStorage(store: VisitorStore) {
    set(this.current, store.current);
  }

  private loadExternalScripts() {
      let s = document.createElement('script');
      s.type = "text/javascript"
      s.async = true;
      s.src = `https://www.googletagmanager.com/gtag/js?id=${this.uaKey}`;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
  
      // you can add facebook-pixel and other cookies here
  }
}
