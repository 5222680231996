import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons'
import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { SquidexGallery, SquidexTexts } from 'generated/gatsbyApiTypes'
import { getLanguageValue } from 'utils'
import { Button, Drawer, FancyNumber, Search } from 'components'
import elasticlunr from 'elasticlunr'
import Gallery from 'components/gallery'

export interface GalleryFilterBarProps {
  gallery: SquidexGallery;
  onFilterChange?: (filters: IGalleryFilter[]) => void;
  style?: React.CSSProperties;
  navigate: (route: string) => void;
}

export interface GalleryFilterBarState {
  drawerOpen: boolean;
  selectedFilters: IGalleryFilter[];
  activeFilters: IGalleryFilter[];
}

export interface IGalleryFilter {
  name: string;
  value: string;
}

export class GalleryFilterBar extends React.Component<GalleryFilterBarProps, GalleryFilterBarState> {
  constructor(props: GalleryFilterBarProps) {
    super(props);
    this.state = {
      drawerOpen: false,
      selectedFilters: [],
      activeFilters: []
    };
  }
  render() {
    const filters = this.props.gallery.galleryItems
      .filter((t) => t.tags !== null)
      .map((t) => getLanguageValue(t.tags))
      .filter((tags) => this.state.selectedFilters.map((f) => {
        return tags.findIndex((t) => t.name === f.name && t.value === f.value) !== -1;
      }).reduce((acc, next) => acc && next, true))
      .reduce((acc, next) => {
        for (const tag of next) {
          if (typeof(tag.value) === "undefined" || tag.value === null || tag.value === "") {
            continue;
          }
          if (typeof(tag.name) === "undefined" || tag.name === null || tag.name === "") {
            continue;
          }
          const arr = acc[tag.name] || [];
          if (arr.indexOf(tag.value) === -1) {
            arr.push(tag.value);
          }
          acc[tag.name] = arr;
        }
        return acc;
      }, {} as { [k: string]: string[] });
    return (<StaticQuery
      query={graphql`
      {
        siteSearchIndex {
          index
        }
        squidexTexts(identity:{eq: "squidextexts"}) {
          txtSearchLabel {
            de
            nl
            en
         }
         txtSearchPlaceholder {
           de
           nl
           en
         }
         txtSearchNoResults {
           de
           nl
           en
         }
          txtFilters {
            de
            nl
            en
          }
          txtApplyFilters {
            de
            nl
            en
          }
          txtClearFilters {
            de
            nl
            en
          }
        }
      }`}
      render={
        (data: { squidexTexts: SquidexTexts, siteSearchIndex: any }) => {
          return (
            <div style={{ ...this.props.style }} className="w-100 mb2 h3 bg-white z-1">
              <div className="flex h-100 items-center justify-between">
                <div onClick={this.onFilterButtonClick} className="tc link dim pointer br2 dib ph4 fw5 pv2 white bg-theme mr2">
                  <FontAwesomeIcon icon={faSlidersH} color="white" size="1x" className="relative" />
                  <span className="ml2 dib-ns dn">{getLanguageValue(data.squidexTexts.txtFilters)}</span>
                  <FancyNumber className="ml2 dib-ns dn" number={this.state.activeFilters.length} color="white" textColor="black" size={5} />
                </div>
                {/* <Search
                  txtLabel={getLanguageValue(data.squidexTexts.txtSearchLabel)}
                  txtPlaceholder={getLanguageValue(data.squidexTexts.txtSearchPlaceholder)}
                  txtNoResults={getLanguageValue(data.squidexTexts.txtSearchNoResults)}
                  navigate={this.props.navigate}
                  searchIndex={data.siteSearchIndex.index}
                  filterResults={this.filterSearchResults}
                /> */}
              </div>
              <Drawer
                header={getLanguageValue(data.squidexTexts.txtFilters)}
                onClose={this.onDrawerClose}
                open={this.state.drawerOpen}
                footer={(
                  <div className="flex-ns justify-around">
                    <Button
                      className="mb2 mb0-ns w-100 w-auto-ns"
                      label={getLanguageValue(data.squidexTexts.txtApplyFilters)}
                      color="theme"
                      onClick={this.onApplyFilters}
                    />
                    <Button
                      className="ml0 ml2-ns w-100 w-auto-ns"
                      label={getLanguageValue(data.squidexTexts.txtClearFilters)}
                      color="theme"
                      inversed
                      onClick={this.onClearFilters} />
                  </div>
                )}
              >
                {this.renderFilters(filters)}
              </Drawer>
            </div>
          )
        }
      } />
    )
  }
  onFilterButtonClick = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  }
  renderFilters = (filters: { [k: string]: string[] }) => {
    const elems = [] as React.ReactNode[];
    for (const key in filters) {
      const getClassName = (val: string) => {
        const activeItem = this.state.selectedFilters.find((f) => f.name === key && f.value === val);
        const active = activeItem ? true : false;
        const bg = active ? "bg-theme" : "bg-white";
        const b = active ? "" : "b--theme";
        const textCol = active ? "white" : "black";
        return `pa2 mr2 mb2 ${bg} ${b} ${textCol} ba br-pill dib dim pointer`
      }
      elems.push(
        <div key={`gfb_filter_${key}`} className="pa3 bb b--light-gray">
          <h4 className="mt0">{key}</h4>
          {
            filters[key].map((f) => (
              <div key={`gfb_filter_${key}_${f}`} className={getClassName(f)} onClick={this.selectFilter(key, f)}>
                <span className="ma2">{f}</span>
              </div>
            ))
          }
        </div>
      )
    }
    return elems;
  }
  selectFilter = (name: string, value: string) => () => {
    const activeItem = this.state.selectedFilters.find((f) => f.name === name && f.value == value);
    let selectedFilters = [...this.state.selectedFilters] //.filter((f) => f.name !== name);
    if (!activeItem) {
      selectedFilters.push({ name, value });
    } else {
      selectedFilters = selectedFilters.filter((f) => f.value !== value || f.name !== name);
    }
    this.setState({ selectedFilters })
  }
  onApplyFilters = () => {
    this.setState({ drawerOpen: false, activeFilters: [...this.state.selectedFilters] })
    if (this.props.onFilterChange) {
      this.props.onFilterChange([...this.state.selectedFilters])
    }
  }
  onDrawerClose = () => {
    this.setState({ drawerOpen: false })
  }
  onClearFilters = () => {
    this.setState({ drawerOpen: false, activeFilters: [], selectedFilters: [] });
    if (this.props.onFilterChange) {
      this.props.onFilterChange([]);
    }
  }
  filterSearchResults = (result: elasticlunr.SearchResults, index: elasticlunr.Index<any>) => {
    const doc = index.documentStore.getDoc(result.ref);
    return doc.gallery === getLanguageValue(this.props.gallery.name);
  }
}

export default GalleryFilterBar
