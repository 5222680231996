import { toJS, autorun, set } from "mobx"
import store from "store"
import { IStore } from "./interfaces";

export default function(_this: IStore) {
	let firstRun = true
	// will run on change
	autorun(() => {
		// on load check if there's an existing store on localStorage and extend the store
		if (firstRun) {
			const existingStore = store.get(_this.key);
			if (existingStore && existingStore.version === _this.version) {
				_this.loadFromStorage(existingStore);
			}
		}
        // from then on serialize and save to localStorage
		store.set(_this.key, toJS(_this));
	})
	firstRun = false
}