import * as React from "react";
import { default as Select } from "react-select";

export interface SelectListProps {
  className?: string;
  label?: string;
  horizontal?: boolean;
  placeholder?: string;
  items: SelectListItem[];
  defaultValue?: SelectListItem;
  onChange?: (t: SelectListItem) => void;
}

export interface SelectListItem {
  label: string;
  value: any;
}

export interface SelectListState {
  value: SelectListItem
}

export default class SelectList extends React.Component<SelectListProps, SelectListState> {
  constructor(props: SelectListProps) {
    super(props);
    this.state = {
      value: props.defaultValue
    }
  }
  componentWillReceiveProps(props: SelectListProps) {
    if (this.props.defaultValue !== props.defaultValue) {
      this.setState({ value: props.defaultValue })
    }
  }
  render() {
    const label = this.props.label ? <label className="f6 b db mb2 tl">{this.props.label}</label> : undefined;
    const selectOpts: any = {};
    selectOpts.className = this.props.className;
    selectOpts.onChange = this.onChange;
    selectOpts.options = this.props.items;
    selectOpts.placeholder = this.props.placeholder;
    selectOpts.defaultValue = this.props.defaultValue;
    selectOpts.value = this.state.value;
    selectOpts.isSearchable = false;
    if (!this.props.horizontal) {
      return <Select {...selectOpts} theme={this.themeFn} />
    }
    return (
      <div className="db flex-ns items-center">
        <div className="fl w-third-ns w-100">
          {label}
        </div>
        <div className="fl w-two-thirds-ns w-100">
          <Select {...selectOpts} theme={this.themeFn} styles={{
            indicatorSeparator: () => ({
              display: "none"
            }),
            control: (base, props) => ({
              ...base,
              borderRadius: 0
            })
          }} />
        </div>
      </div>
    )
  }
  onChange = (item: SelectListItem) => {
    this.setState({ value: item });
    if (this.props.onChange) {
      this.props.onChange(item);
    }
  }
  themeFn = (theme: any) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: process.env.GATSBY_THEME_COLOR ? process.env.GATSBY_THEME_COLOR : "#000"
      }
    }
  }
}


