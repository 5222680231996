import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import React, { Component } from 'react'
import ReactDOM from 'react-dom';

export interface DrawerProps {
  open: boolean;
  header?: string;
  footer?: React.ReactNode;
  onClose?: () => void;
}

export interface DrawerState {
  opened: boolean;
  scrollY: number;
}

export class Drawer extends Component<DrawerProps, DrawerState> {
  el: HTMLElement;
  constructor(props: any) {
    super(props);
    if (typeof (document) !== "undefined") {
      this.el = document.getElementById("gbp_drawer");
    }
    this.state = {
      opened: false,
      scrollY: 0
    }
  }
  componentWillUnmount() {
    if (typeof (document) !== "undefined") {
      document.body.parentElement.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }
  }
  static getDerivedStateFromProps(nextprops: DrawerProps, prevState: DrawerState) {

    if (nextprops.open && !prevState.opened) {
      if (typeof (document) !== "undefined") {
        const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.parentElement.style.overflow = "hidden";
        document.body.style.paddingRight = scrollBarWidth + "px";
      }
      return ({ opened: true, scrollY: window.scrollY });
    }
    if (!nextprops.open && prevState.opened) {
      if (typeof (document) !== "undefined") {
        document.body.parentElement.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }
      return ({ opened: false, scrollY: window.scrollY });
    }
    return null;
  }
  render() {
    if (this.state.opened) {
      return ReactDOM.createPortal(
        <div className="w-80 w-50-ns h-100 w-25-l flex flex-column absolute right-0 z-999 bg-white" style={{ top: this.state.scrollY, boxShadow: "-5px 9px 8px -8px #0A0A0A" }}>
          <div className="flex justify-between items-center pa3 bb b--light-gray">
            <h4 className="ma0">{this.props.header}</h4>
            <FontAwesomeIcon icon={faTimes} color="black" size="1x" className="dim pointer right-0" onClick={this.props.onClose} />
          </div>
          <div style={{ overflowY: "auto" }}>
            {this.props.children}
          </div>
          {this.props.footer ? (
            <>
              <div style={{flexGrow: 1}} />
              <div className="pa3 bt b--light-gray">
                {this.props.footer}
              </div>
            </>
          ) : null}
        </div>
        , this.el)
    }
    return null;
  }
}

export default Drawer
