import * as React from "react";
import { History } from "history";

export interface GatsbyLayoutProps<D = any, C = any>
{
    children: () => any;
    data: D;
    history: History;
    layout: boolean;
    layoutContext: C;
    location: {
        hash: string;
        pathName: string;
        search: string;
        state: string;
    }
    match: {
        isExact: false;
        params: any;
        path: string;
        url: string;
    }
    staticContext: undefined
    navigate: (location: string, opts?: any) => void;
}

export class GatsbyLayoutComponent<D = any, C = any> extends React.Component<GatsbyLayoutProps<D, C>, any>
{

}

export interface GatsbyPageProps<D = any, C = any>
{
    data: D;
    history: History;
    location: {
        hash: string;
        pathName: string;
        search: string;
        state: string;
    }
    
    match: {
        isExact: false;
        params: any;
        path: string;
        url: string;
    }
    page: boolean;
    pageResources: {
        component: React.Component;
        json: any;
        layout: (props: any) => React.Component;
        page: {
            componentChunkName: string;
            jsonName: string;
            layout: string;
            layoutComponentChunkName: string;
            path: string;
        }
    }
    pathContext: C;

    staticContext: undefined
    navigate: (location: string, opts?: any) => void;
}

export class GatsbyPageComponent<D = any, C = any> extends React.Component<GatsbyPageProps<D, C>, any>
{

}
