import wrapRoot from "./wrapRoot"
import wrapPage from "./wrapPage"
import { init } from "@sentry/browser"

export const wrapRootElement = wrapRoot;
export const wrapPageElement = wrapPage;
export const onClientEntry = () => {
  init({ 
    release: `gatsbyrint@${process.env.GATSBY_BUILD}`,
    dsn: 'https://5edd615f335b48a492653bbde4b6c04f@sentry.io/1382420' 
  });    
  (function () {
    var _tsid = process.env.GATSBY_TRUSTED_SHOP_ID;
    window._tsConfig = {
        'yOffset': '0', //offset from page bottom
        'variant': 'reviews', //text, default, small, reviews
        'disableResponsive': 'true'
    };
    var _ts = document.createElement('script');
    _ts.type = 'text/javascript';
    _ts.charset = 'utf-8';
    _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js';
    var __ts = document.getElementsByTagName('script')[0];
    __ts.parentNode.insertBefore(_ts, __ts);
    })();
}
