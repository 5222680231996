
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faSmile, faShoppingCart } from '@fortawesome/pro-regular-svg-icons'
import { graphql, StaticQuery } from 'gatsby';
import { inject, observer } from 'mobx-react';
import * as React from "react";

import { SquidexConfig, SquidexConfigConfidenceStatements, SquidexMenuItem, SquidexTexts } from 'generated/gatsbyApiTypes';
import { FancyNumber, Search } from "components"
import { getLanguageValue } from 'utils';
import { OrderStore } from 'stores';

export interface DesktopMenuPresentationProps {
  nodes: SquidexMenuItem[];
  tagline: string;
  title: string;
  orderStore?: OrderStore;
  navigate: (route: string) => void;
}

export interface DesktopMenuPresentationState {
}

@inject("orderStore")
@observer
export default class DesktopMenuPresentation extends React.Component<DesktopMenuPresentationProps, DesktopMenuPresentationState> {
  render() {
    return (
      <StaticQuery
        query={graphql`
        {
          siteSearchIndex {
            index
          }
          texts: squidexTexts(identity: {eq: "squidextexts"}) {
            txtSearchLabel {
               de
               nl
               en
            }
            txtSearchPlaceholder {
              de
              nl
              en
            }
            txtSearchNoResults {
              de
              nl
              en
            }
          }
          squidexConfig(identity: {eq: "config"}) {
            pageCartSlug {
              iv
            }
            confidenceStatements {
              de {
                text
                showCheckmark
                showSmile
              }
              nl {
                text
                showCheckmark
                showSmile
              }
              en {
                text
                showCheckmark
                showSmile
              }
            }
          }
      }
    `}
        render={data => {
          const config = data.squidexConfig as SquidexConfig;
           const texts = data.texts as SquidexTexts;
          return (<div id="desktop-navbar" className="dn db-ns">
            <div style={{ height: "24px" }} className="bg-light-gray">
              <div className="center h-100 items-center flex mwPage">
                {this.renderStatements(config.confidenceStatements)}
              </div>
            </div>
            <div style={{ height: "112px" }} className="mwPage center flex items-center justify-between">
              <div className="flex items-center">
                <a className="dim f1 f-logo black mr3" href="/" title="Home">{this.props.title}</a>
                <span className="f5 i">{this.props.tagline}</span>
              </div>
              <img className="self-end" src="https://widgets.trustedshops.com/images/trustmark_180x180.png" height="64px" width="64px" />
            </div>
            <nav className={"bg-theme pa2 pa3-ns shadow-2 w-100 z-5"}>
              <div className="center flex items-center justify-left mwPage h-100">
                {this.renderChildren()}
                <button 
                  className="bg-theme dim bn relative pointer"
                  onClick={this.onCartClick(config.pageCartSlug.iv.toLowerCase())} 
                  style={{ marginLeft: "auto" }}>
                  <FontAwesomeIcon style={{
                    fontSize: "2em",
                    width: "1.125em"
                  }} icon={faShoppingCart} size="2x" color="white" />
                  <div className="absolute" style={{top: "16px", left: "24px"}}>
                  <div className="tc f5 lh-copy w1 black bg-white dib br-100" style={{
                    width: "1.5rem"
                  }}>{this.props.orderStore.current.items.length}</div>
                  </div>
                </button>
              </div>
            </nav>
          </div>
          )
        }} />
    )
  }
  renderChildren = () => {
    return this.props.nodes.map((node, i) => {
      // if (!node.submenu || node.submenu.length === 0) {
      return <a
        key={`menu-item-d-${i}`}
        className="dim white relative dn dib-ns mr3"
        href={node.href.iv.toLowerCase()}
        title={node.name.iv}>
        {node.name.iv}
      </a>;
    })
  }
  renderStatements = (statements: SquidexConfigConfidenceStatements) => {
    return getLanguageValue(statements).map((s, i) => (
      <div key={`statements_${i}`} className="mr3">
        {s.showCheckmark ? <FontAwesomeIcon style={
          {height: "1em", verticalAlign: "-0.125em"}
        } icon={faCheck} color="black" size="1x" /> : null}
        {s.showSmile ? <FontAwesomeIcon style={
          {height: "1em", verticalAlign: "-0.125em"}
        } icon={faSmile} color="black" size="1x" /> : null}
        <span className="ml2">{s.text}</span>
      </div>
    ))
  }
  onCartClick = (url: string) => () => {
    this.props.navigate(url);
  }
}



