import * as React from "react"
import AsyncSelect from "react-select/async"
import elasticlunr, { Index } from "elasticlunr"
import { OptionsType,  OptionTypeBase } from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import { Provider } from "mobx-react";

export interface SearchProps {
  searchIndex: any;
  navigate: (route: string) => void;
  txtNoResults: string;
  txtPlaceholder: string;
  txtLabel: string;
  createQuery?: (query: string) => string;
  setOptions?: (query: string) => elasticlunr.SearchConfig<any>;
  filterResults?: (result: elasticlunr.SearchResults, index: elasticlunr.Index<any>) => boolean; 
}

// Search component
export default class Search extends React.Component<SearchProps> {
  index: Index<any>;
  selectList: AsyncSelect<OptionTypeBase, false>
  render() {
    const customDropdownIcon = () => 
      (<FontAwesomeIcon className="mr2" icon={faSearch} size="sm" />)
    return (
      <div className="dib" style={{width: "20em"}}>
        <span>{this.props.txtLabel}</span>
        <AsyncSelect
          placeholder={this.props.txtPlaceholder}
          loadOptions={this.search  }
          onChange={this.onSelect}
          isClearable
          theme={this.themeFn}
          value={null}
          noOptionsMessage={() => this.props.txtNoResults}
          components={{
            DropdownIndicator: customDropdownIcon
          }}
          menuPortalTarget={typeof(document) !== "undefined" ? document.body : undefined}
          styles={{
            menuPortal: (provided, state) => ({
              ...provided,
              zIndex: 100
            }),
            indicatorSeparator: () => ({
              display: "none"
            }),
            control: (base, props) => ({
              ...base,
              borderRadius: 0
            })
          }}
        />
      </div>
    )
  }
  onSelect = (option: any) => {
    if (!option) {
      return;
    }
    if (this.props.navigate) {
      this.props.navigate(option.value);
    }
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
      Index.load(this.props.searchIndex)

  search = (query: string, callback: ((options: OptionsType<OptionTypeBase>) => void)) => {
    this.index = this.getOrCreateIndex();
    const getQuery = this.props.createQuery ? this.props.createQuery : (s: string) => s;
    const getOpts = this.props.setOptions ? this.props.setOptions : (s: string) => ({ expand: true});
    const filterResults = this.props.filterResults ? this.props.filterResults : () => true;
    callback(
      // Query the index with search string to get an [] of IDs
      this.index
        .search(getQuery(query), getOpts(query))
        .filter((r) => filterResults(r, this.index))
        // Map over each ID and return the full document
        .map(({ ref }) => {
          const doc = this.index.documentStore.getDoc(ref);
          return {
            label: doc.title,
            value: doc.path
          }
        }),
    )
  }
  themeFn = (theme: any) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: process.env.GATSBY_THEME_COLOR ? process.env.GATSBY_THEME_COLOR : "#000"
      }
    }
  }
}
