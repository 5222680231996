
import * as React from "react";

export interface RadioListProps {
  horizontal?: boolean;
  className?: string;
  defaultValue?: RadioListItem;
  items: RadioListItem[];
  onChange?: (value: RadioListItem) => void;
}

export interface RadioListItem {
  value: any;
  label: string;
}

export default class RadioList extends React.Component<RadioListProps> {
  id: number;
  componentWillMount() {
    this.id = Math.random() * 2102938;
  }
  render() {
    return (
      <div className={this.props.className}>
        {this.props.items.map(this.renderItem)}
      </div>
    );
  }
  renderItem = (item: RadioListItem, index: number) => {
    const label = <label className="db tl ml2">{item.label}</label>;
    const style = "pa2 db";
    const checked = this.props.defaultValue && this.props.defaultValue.label == item.label;
    if (this.props.horizontal) {
      return (
        <div key={`rbl-${this.id}-${index}`} className="inline-flex items-center mb2 mr2">
          <input className={style} type="radio" name={`rbl-${this.id}`} defaultChecked={checked} onChange={this.handleChange(item)} />
          {label}
        </div>
      )
    }
    return (
      <div key={`rbl-${this.id}-${index}`} className="flex items-center mb2 w-100">
        <input className={style} type="radio" name={`rbl-${this.id}`} defaultChecked={checked} onChange={this.handleChange(item)} />
        {label}
      </div>
    )
  }
  handleChange = (item: RadioListItem) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(item);
    }
  }
}


