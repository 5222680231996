import { observer, inject } from "mobx-react"
import * as React from "react";
import { navigate } from "gatsby";

import { VisitorStore } from "stores";

import Navbar from "../navbar";
import Footer from "../footer";

import "./index.less"
import { NAVIGATE_EVENT } from "../../utils/constants";
import Breadcrumbs from '../breadcrumbs';

@inject("uiStore", "visitorStore")
@observer
export default class Layout extends React.Component<any> {
  componentWillMount() {
    if (typeof(window) !== "undefined") {
      window.addEventListener("click", (ev: MouseEvent) => {
        const target = ev.target as Element;
        if (target && target.tagName && target.tagName.toLowerCase() === "a") {
          const anchorElement = target as HTMLAnchorElement;
          if (anchorElement.host === window.location.host && anchorElement.target !== "_blank" && anchorElement.hash[0] !== "#") {
            ev.preventDefault();
              const visitorStore: VisitorStore = this.props.visitorStore;
              visitorStore.logEvent(NAVIGATE_EVENT, {
                location: this.props.location,
                href: anchorElement.pathname
              });
            navigate(anchorElement.pathname);
          }
        } 
      })
    }
  }
  render() {
    const style: React.CSSProperties = {
      minHeight: "calc(100vh - 277px)",
    }
    return (
      <>
        <Navbar navigate={this.props.navigate} />
        <main className="mwPage center h100 pl3 pr3 pa0-l" style={style}>
           <Breadcrumbs location={this.props.location} />
          {this.props.children}
        </main>
        <Footer />
      </>
    )
  }
  
}
