import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons"

import { SquidexProductPageQualitiesDe } from "../../generated/gatsbyApiTypes"

export interface ProductQualityProps {
  node: SquidexProductPageQualitiesDe;
}

export default class ProductQuality extends React.Component<ProductQualityProps> {
  render() {
    const node = this.props.node;
    const cs = node.positive ? "green" : "red";
    const icon = node.positive ? faCheck : faTimes;
    return (
      <div className="inline-flex items-center mb3 w-100">
        <div className={`${cs} mr3`}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <p className="ma0">{node.text}</p>
      </div>
    )
  }
}


