
import * as React from "react";
import SpotlightItem from './spotlightItem';
import { SquidexSpotlightItem } from 'generated/gatsbyApiTypes';

export interface SpotlightsContainerProps {
  nodes: SquidexSpotlightItem[]
}

export default class SpotlightsContainer extends React.Component<SpotlightsContainerProps> {
  render() {
    return (
      <>
        {
          this.props.nodes.map((node, i) =>
            <SpotlightItem
              node={node}
              reverse={i % 2 === 0}
              key={`SpotlightItem-${i}`}
            />
          )
        }
      </>
    )
  }
}


