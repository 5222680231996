
import * as React from "react";
import Img from "gatsby-image";
import SlickSlider, { ResponsiveObject } from "react-slick";

import { SquidexAsset } from 'generated/gatsbyApiTypes';
import { Lightbox } from 'components';
import { getFixedImageSharp } from 'utils';

import "./index.less"

export interface SliderProps {
  nodes: SquidexAsset[];
  excludeLightBox?: boolean;
  clickToEnlargeText: string;
  slidesToShow?: number;
  className?: string;
  responsive?: ResponsiveObject[]
}

export interface SliderState {
  currentImage: number;
}

export default class Slider extends React.Component<SliderProps, SliderState> {
  constructor(props?: SliderProps) {
    super(props)
    this.state = {
      currentImage: -1
    }
  }
  render() {
    const images = this.props.nodes.map((node) => node.childImageSharp.fixed);
    const numSlides = this.props.slidesToShow ? this.props.slidesToShow : 3;
    const showLightBox = this.props.excludeLightBox ? false : true;
    const responsive = this.props.responsive ? this.props.responsive : [{
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    }, {
      breakpoint: 960,
      settings: {
        slidesToShow: 2
      }
    }]
    const containerCs = showLightBox ? "dim pointer hide-child relative" : "relative";
    return (
      <div className={this.props.className}>
        <SlickSlider
          dots={true}
          infinite={true}
          speed={500}
          slidesToShow={images.length > numSlides ? numSlides : images.length}
          slidesToScroll={1}
          responsive={responsive}
        >
          {
            this.props.nodes.map((node, i) => (
              <a className={containerCs} key={`example-${i}`} onClick={this.setLightboxImage(i)}>
                <Img className="h-100" fixed={getFixedImageSharp(node)} />
                {showLightBox ? (
                <div className={`absolute child h-50 left-0 pa2`} style={{ width: "250px", top: "28%" }}>
                  <h3 className="ma0 w-100 black tc">{this.props.clickToEnlargeText}</h3>
                </div>
                ) : null}
              </a>
            ))
          }
        </SlickSlider>
        {showLightBox ? <Lightbox images={images} imageIndex={this.state.currentImage} /> : null }
      </div>
    )
  }
  setLightboxImage = (i: number) => {
    return () => this.setState({ currentImage: i });
  }
}


