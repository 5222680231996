import * as React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

export interface CollapseProps {
  className?: string;
  label: string;
}

export interface CollapseState {
  open: boolean;
}

export default class Collapse extends React.Component<CollapseProps, CollapseState> {
  constructor(props: CollapseProps) {
    super(props);
    this.state = {
      open: false
    }
  }
  render() {
    return (<div className="mt2 bt b--gray">
      <div className="link pointer dim flex justify-between" onClick={this.onCollapseClick}>
        <h4 className="dib mb2 mt2">{this.props.label}</h4>
        <FontAwesomeIcon className="self-center" icon={this.state.open ? faChevronUp : faChevronDown}  />
      </div>
      {this.state.open ? this.props.children : null}
    </div>)
  }
  onCollapseClick = () => {
    this.setState({open: !this.state.open});
  }
}


